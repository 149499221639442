html{
    background-color: black;
  }
  
  @font-face {
    font-family: Marcellus;
    src: url("../../assets/fonts/Marcellus-Regular.ttf");
  }
  
  @font-face {
    font-family: ManropeSemiBold;
    src: url("../../assets/fonts/manrope.semibold.otf");
  }
  
  .contact-main{
    color: white;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  
  .contact-body{
    background-image: url("../../assets/images/i.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(41, 41, 41);
    width: 100%;
  }
  
  .contact-header{
    font-family: Marcellus;
    font-size: 70px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 40px;
    padding-top: 20px;
    color: white;
  }

  .contact-us-parent{
    font-family: ManropeSemiBold;
    display: flex;
    flex-flow: wrap row;
    width: 90%;
    margin: 0 auto;
    justify-content: space-around;
  }
  
  .contact-us-form{
    height: fit-content;
    width: 40%;
  }
  
  .contact-us-maps{
    width: 40%;
  }

  .contact-us-maps img, .contact-us-maps iframe{
    aspect-ratio: 4/3;
    width: 400px;
  }

  .college-address{
    width: 400px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  
  .form-input{
    height: 70px;
    font-family: ManropeRegular;
    font-size: 20px;
    color: white;
  }
  
  input{
    font-family: ManropeRegular;
    font-size: 19px;
    color: white;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.446);
  }
  
  input[type=text],
  input[type=number],
  input[type=tel], 
  input[type=email]{ 
    height: 40px;
    width: 80%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0);
  }
  
  input[type=checkbox]{
    border: none;
    height: 20px;
    width: 20px;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  input:focus{
    outline: none;
  }
  
  ::placeholder{
    font-family: ManropeSemiBold;
    font-size: 20px;
    opacity: 0.5;
  }
  
  .submit{
    cursor: pointer;
    width: 120px;
    height: 60px;
    border-radius: 40px;
    border: none;
    font-size: 100%;
    font-family: ManropeSemiBold;
  }
  
  /* Media queries */
  @media screen and (max-width: 1200px){
    .contact-body{
      background-image: url("../../assets/images/mobile-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .contact-text{
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 1024px){
    .contact-header{
      font-size: 55px;
    }
  }
  
  @media screen and (max-width: 880px){
    .contact-us-parent{
      display: block;
      width: 90%;
    }
    .contact-us-form{
      width: 90%;
      margin: 30px auto;
    }
    .contact-us-maps{
      width: 90%;
      margin: 30px auto;
    }
    .contact-carousel{
      width: 90%;
      border-radius: 15px;
      padding: 10px;
    }
    .carousel{
      border-radius: 5px;
    }
    .contact-text{
      width: 85%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .contact-header{
      font-size: 45px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .contact-text{
      font-size: 16px;
      margin: 20px auto;
    }
  }

  @media screen and (max-width: 515px) {
    /* .contact-body{
      margin-top: 100px;
    } */
    .contact-us-maps img, .contact-us-maps iframe, .college-address{
      width: 95%;
    }
    .contact-us-maps{
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 450px) {
    /* .contact-header{
      font-size: 40px;
    } */
    .contact-carousel{
      width: 90%;
      border-radius: 10px;
      padding: 2px;
      margin-bottom: 30px;
    }
    .carousel{
      border-radius: 8px;
    }
    .contact-text{
      font-size: 13px;
    }
  }
  
  @media screen and (max-width: 375px){
    ::placeholder{
      font-size: 15px;
    }
    
    .contact-header{
      font-size: 35px;
    }
    .form-input{
      font-size: 16px;
    }
    .contact-text{
      font-size: 11px;
    }
  }
  
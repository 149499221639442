
.past-team-body{
  width: 100%;
  /* margin-top: 120px; */
}

@font-face {
  font-family: Marcellus;
  src: url("../../assets/fonts/Marcellus-Regular.ttf");
}

@font-face {
  font-family: ManropeSemiBold;
  src: url("../../assets/fonts/manrope.semibold.otf");
}

.past-team-header{
  font-family: Marcellus;
  font-size: 70px;
  text-align: center;
  padding-top: 20px;
  color: white;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  /* background: linear-gradient(to bottom, white, white 45%, rgb(124, 124, 124));
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.past-team-admin-heads{
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
  justify-content: space-evenly;
}

.past-team-coordinators{
  display: flex;
  flex-flow: wrap row;
  width: 80%;
  margin: 0 auto;
  justify-content: space-evenly;
}

@media screen and (max-width: 1498px){
  .past-team-admin-heads{
    width: 80%;
  }
}

@media screen and (max-width: 1290px){
  .past-team-coordinators{
    width: 80%;
  }
}

@media screen and (max-width: 940px){
  .past-team-coordinators{
    width: 90%;
  }
}

@media screen and (max-width: 880px){
  .past-team-header{
    font-size: 60px;
  }
  .past-team-top-core > div{
    margin: 20px 0px;
    font-size: 14px;
  }
  .past-team-coordinators{
    width: 90%;
  }  
}


@media screen and (max-width: 625px){
  .past-team-admin-heads{
    width: 100%;
  }
  .past-team-coordinators{
    width: 100%;
  }
  .past-team-header{
    font-size: 50px;
  }
  .past-team-dropdown{
    font-size: 25px;
    padding: 30px;
    width: 80%;
    text-align: center;
  }
  
  .past-team-dropdown select{
    height: 25px;
    width: 100px;
    font-size: 13px;
    text-align: center;
  }
}

@media screen and (max-width: 370px) {
  .past-team-header{
    font-size: 40px;
  }
}

@media screen and (max-width: 300px) {
  .past-team-header{
    font-size: 35px;
  }
}

/* @media screen and (max-width: 515px) {
  .past-team-body{
    margin-top: 100px;
  }
} */
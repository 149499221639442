
html{
  background-image: url("../../assets/images/i.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.not-found-body{
  width: 100%;
  /* margin-top: 120px; */
}

@font-face {
  font-family: AbrilFatface;
  src: url("../../assets/fonts/AbrilFatface-Regular.ttf");
}

@font-face {
  font-family: ManropeSemiBold;
  src: url("../../assets/fonts/manrope.semibold.otf");
}

.not-found-header{
  font-family: AbrilFatface;
  font-size: 70px;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 20px;
  background: linear-gradient(to bottom, white, white 45%, rgb(124, 124, 124));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.not-found-text{
  font-family: ManropeSemiBold;
  font-size: xx-large;
  text-align: center;
  color: white;
}

/* @media screen and (max-width: 515px) {
  .teachers-body{
    margin-top: 100px;
  }
} */

html{
  background-image: url("../../assets/images/i.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.gallery-body{
  width: 100%;
  /* margin-top: 120px; */
}

@font-face {
  font-family: AbrilFatface;
  src: url("../../assets/fonts/AbrilFatface-Regular.ttf");
}

@font-face {
  font-family: ManropeSemiBold;
  src: url("../../assets/fonts/manrope.semibold.otf");
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* 3 equal columns */
.column {
  flex: 33%;
  max-width: 33%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}



/* @media screen and (max-width: 515px) {
  .gallery-body{
    margin-top: 100px;
  }
} */
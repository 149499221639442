@font-face {
  font-family: ManropeSemiBold;
  src: url("../assets/fonts/manrope.semibold.otf");
}

.footer{
  width: 100%;
  align-items: center;
  margin-top: 40px 0;
  padding-top: 20px;
  bottom: 0;
}

.footer-menu {
  display: flex;
  list-style: none;
  text-align: center;
  height: 100px;
  width: 600px;
  align-items: center;
  margin: 0 auto;
}

.footer-item {
  height: 20px;
  margin: 0 auto;
}

.footer-links{
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.footer-links img{
  height: 40px;
  width: 40px;
}

.footer-copyright-parent{
  padding-bottom: 20px;
}

.footer-copyright{
  color: white;
  font-family: ManropeSemiBold;
  text-align: center;
  margin: 5px;
}

@media screen and (max-width: 620px){
  .footer-menu{
    width: 90%;
  }
  .footer-links img{
    height: 35px;
    width: 35px;
  }
}

@media screen and (max-width: 500px){
  .footer-links img{
    height: 30px;
    width: 30px;
  }
  .footer-copyright{
    font-size: 12px;
  }
}

@media screen and (max-width: 400px){
  .footer-links img{
    height: 22px;
    width: 22px;
  }
}

@media screen and (max-width: 350px){
  .footer-links img{
    height: 18px;
    width: 18px;
  }
  .footer-copyright{
    font-size: 10px;
  }
}

@media screen and (max-width: 295px){
  .footer-links img{
    height: 18px;
    width: 18px;
  }
  .footer-copyright{
    font-size: 10px;
  }
  /* .footer{
    
    overflow: hidden;
  } */
  .footer-menu{
    display: flex;
    flex-flow: row wrap;
  }
  
}


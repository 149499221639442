
html{
  background-image: url("../../assets/images/i.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.achievements-body{
  width: 100%;
  /* margin-top: 120px; */
}

@font-face {
  font-family: Marcellus;
  src: url("../../assets/fonts/Marcellus-Regular.ttf");
}

@font-face {
  font-family: Amsterdam;
  src: url("../../assets/fonts/Amsterdam.otf");
}

@font-face {
  font-family: ManropeSemiBold;
  src: url("../../assets/fonts/manrope.semibold.otf");
}

.achievements-header{
  font-family: Marcellus;
  font-size: 70px;
  text-align: center;
  padding-top: 20px;
  color: white;
  width: 90%;
  margin: 0 auto;
  /* background: linear-gradient(to bottom, white, white 65%, rgb(124, 124, 124));
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.achievements-text{
  width: 60%;
  margin: 0 auto;
  text-align: center;
  font-size: 26px;
  font-family: ManropeSemiBold;
  color: white;
}

.achievements-list-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffd90000;
  background-color: black;
  padding: 20px;
  border-radius: 50px;
  margin: 40px 0;
  transition: all 3s ease;
}

.achievements-list-parent:hover{
  transition: all 3s ease;
  border: 1px solid #FFD700;
}

.achievements-list-parent img{
  background-color: white;
  border-radius: 10px;
  aspect-ratio: 1/1;
  width: 150px;
}

.achievements-list-parent img:nth-child(odd){
  margin-right: 20px;
}

.achievements-list-parent img:nth-child(even){
  margin-left: 20px;
}


@media screen and (max-width: 1200px){

  .achievements-text{
    width: 80%;
    font-size: 24px;
  }
}

@media screen and (max-width: 1024px){
  .achievements-header{
    font-size: 60px;
  }
  .achievements-list-parent{
    border-radius: 10px;
  }
}

@media screen and (max-width: 880px){
  .achievements-text{
    width: 80%;
    font-size: 18px;
  }
  .achievements-header{
    font-size: 48px;
  }
}

@media screen and (max-width: 768px) {
  .achievements-text{
    width: 80%;
  }
}

@media screen and (max-width: 515px) {
  /* .achievements-body{
    margin-top: 100px;
  } */
  .achievements-header{
    font-size: 40px;
  }
  .achievements-text{
    font-size: 15px;
  }
  .achievements-list-parent{
    margin: 13px 0;
    padding: 10px;
  }
  .achievements-list-parent img{
    width: 80px;
  }
}

@media screen and (max-width: 375px){
  .achievements-header{
    font-size: 30px;
  }
  .achievements-text{
    font-size: 13px;
  }
  .achievements-list-parent img{
    width: 60px;
  }
}

@media screen and (max-width: 300px){
  .achievements-header{
    font-size: 25px;
  }
}




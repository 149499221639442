
html{
  background-image: url("../../assets/images/i.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.teachers-body{
  width: 100%;
  /* margin-top: 120px; */
}

@font-face {
  font-family: Marcellus;
  src: url("../../assets/fonts/Marcellus-Regular.ttf");
}

@font-face {
  font-family: ManropeSemiBold;
  src: url("../../assets/fonts/manrope.semibold.otf");
}
.teacher-card-container{
  justify-content: center;
  height: 100px;
  width: 400px;
  font-family: ManropeSemiBold;
  color: white;
  text-align: center;
  margin: 25px 25px;
}

.teacher-card-name{
  margin-top: 5px;
  font-size: 26px;
}

.teacher-card-position{
  font-size: 18px;
}

@media screen and (max-width: 1200px){
  .teacher-card-container{
    height: 100px;
    width: 300px;
    margin: 25px 25px;
  }
  .teacher-card-name{
    font-size: 23px;
  }
  
  .teacher-card-position{
    font-size: 16px;
  }
}


@media screen and (max-width: 880px){
  .teacher-card-container{
    height: 100px;
    width: 220px;
    margin: 5px 0px;
  }
  .teacher-card-name{
    font-size: 18px;
  }
  .teacher-card-position{
    font-size: 14px;
  }
}

@media screen and (max-width: 500px){
  .teacher-card-container{
    height: 80px;
    width: 180px;
    margin: 10px 0px;
  }
  .teacher-card-name{
    margin-top: 10px;
    font-size: 17px;
  }
  .teacher-card-position{
    font-size: 13px;
  }
  
}


.teachers-header{
  font-family: Marcellus;
  font-size: 70px;
  text-align: center;
  margin: 20px auto;
  padding-top: 20px;
  color: white;
  width: 90%;
  /* background: linear-gradient(to bottom, white, white 45%, rgb(124, 124, 124));
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.teachers-convener{
  width: 30%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.teachers-incharge{
  width: 80%;
  flex-flow: wrap row;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}

.teachers-list{
  width: 80%;
  flex-flow: wrap row;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 515px) {
  .teachers-header{
    font-size: 40px;
  }
  /* .teachers-body{
    margin-top: 100px;
  } */
}
@font-face {
  font-family: ManropeSemiBold;
  src: url("../assets/fonts/manrope.semibold.otf");
}

.past-achievements-list{
  font-family: ManropeSemiBold;
  font-size: 28px;
  background-color: black;
  padding: 20px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0);
  margin: 40px 0;
  font-size: 30px;
  transition: all 1s ease;
}

.past-achievements-list:hover{
  border: 1px solid white;
  transition: all 1s ease;
}


.past-achievements-sublist{
  width: 80%;
  margin: 0 auto;
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  font-size: 20px;
  font-family: ManropeRegular;
  color: white;
  /* list-style: disc; */
  list-style: none;
}

@media screen and (max-width: 768px) {
  .past-achievements-list{
    font-size: 24px;
  }
  .past-achievements-sublist{
    font-size: 18px;
  }
}

@media screen and (max-width: 515px) {
  .past-achievements-list{
    margin: 20px 0;
    padding: 30px;
  }
  .past-achievements-list{
    font-size: 22px;
  }
  .past-achievements-sublist{
    font-size: 16px;
  }
}

@media screen and (max-width: 375px){
  .past-achievements-list{
    font-size: 20px;
  }
  .past-achievements-sublist{
    font-size: 15px;
  }
}

@media screen and (max-width: 300px){
  .past-achievements-list{
    font-size: 18px;
  }
  .past-achievements-sublist{
    font-size: 12px;
  }
}
